var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container--info no-select"},[_c('ul',{staticClass:"list-container",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : '']},[_vm._l((_vm.items),function(item,index){return [_c('li',{key:index},[_c('div',[(item.to.length > 0)?_c('a',{staticClass:"list-item pointer",class:[
                _vm.$store.state.breakpoint.smAndDown ? 'text-medium' : '',
                _vm.$store.state.isTouchDevice ? 'touch-device' : '',
                _vm.$store.state.breakpoint.mdAndUp
                  ? 'os-title'
                  : 'os-title-mobile'
              ],on:{"click":function($event){return _vm.navigate(index, item.to)}}},[_vm._v(" "+_vm._s(item.subMenuTitle)+" "),_c('a',{staticClass:"list-item-sub",class:_vm.$store.state.breakpoint.smAndDown ? 'mobile' : ''},[_vm._v(_vm._s(item.subMenuSubtitle))])]):_c('a',{staticClass:"list-item defaultCursor",class:[
                _vm.$store.state.breakpoint.smAndDown ? 'text-medium' : '',
                _vm.$store.state.breakpoint.mdAndUp
                  ? 'os-title'
                  : 'os-title-mobile'
              ]},[_vm._v(" "+_vm._s(item.subMenuTitle)+" ")])])])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }