






















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OsSocialButton extends Vue {
  @Prop({ default: "" })
  type!: string;

  openUrl() {
    switch (this.type) {
      case "linkedin":
        window.open("https://www.linkedin.com/company/oh-snap/", "_blank");
        break;

      case "facebook":
        window.open("https://www.facebook.com/ohsnap.de/", "_blank");
        break;

      case "twitter":
        window.open("https://twitter.com/oh_snap_social", "_blank");
        break;
    }
  }
}
