











import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class OsLegalInformation extends Vue {
  @Prop({ default: false })
  darkTheme!: boolean;
}
