























import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeClientsTestimonialsRomanBachler extends Vue {
  headerText: string = "Roman Bachler";
  subHeaderText: string = "Managing Director, COSMO CONSULT";
}
