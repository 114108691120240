var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pointer",style:({
    height: _vm.size + 2 * _vm.padding + 'px',
    width: _vm.size + 2 * _vm.padding + 'px'
  }),on:{"click":_vm.goBack}},[_c('svg',{style:({
      height: _vm.size + 'px',
      width: _vm.size + 'px',
      padding: _vm.padding + 'px'
    }),attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('line',{staticStyle:{"stroke-width":"1"},attrs:{"x1":_vm.size,"y1":"0","x2":"0","y2":_vm.size,"stroke":_vm.strokeColor}}),_c('line',{staticStyle:{"stroke-width":"1"},attrs:{"x1":"0","y1":"0","x2":_vm.size,"y2":_vm.size,"stroke":_vm.strokeColor}})])])}
var staticRenderFns = []

export { render, staticRenderFns }