































import { Component, Vue, Prop } from "vue-property-decorator";
import { MainMenuItemModel } from "@/components/MainMenu/main-menu-item.model";

@Component({
  components: {}
})
export default class MainMenuItem extends Vue {
  @Prop({
    required: true
  })
  item!: MainMenuItemModel;

  @Prop({
    required: true,
    default: 400
  })
  height!: number;

  isMenuItemHidden: boolean = false;
  showActiveItemElements: boolean = false;

  $refs!: {
    menuItem: HTMLDivElement;
  };

  mounted() {
    this.showActiveItemElements = this.isCurrentActiveRoute;
  }

  get itemHeight(): string {
    return this.height + "px";
  }

  get itemWidth(): string {
    return this.height / 1.4 + "px";
  }

  get isCurrentActiveRoute(): boolean {
    if (this.$store.state.currentActiveRoute) {
      return this.$store.state.currentActiveRoute.fullPath.includes(
        this.item.to
      );
    }

    return false;
  }

  get menuItemClasses(): string {
    let v = "";

    if (this.item.visible && this.$store.state.breakpoint.mdAndUp) {
      v += "hidden ";
    }

    if (this.$store.state.breakpoint.smAndDown) {
      v += "mobile ";
    }

    if (this.$store.state.isTouchDevice) {
      v += "touch-device ";
    }

    if (
      this.$store.state.isInMainMenuHover &&
      this.$store.state.isInMainMenu &&
      !this.isCurrentActiveRoute
    ) {
      v += "menu-item__inactive";
    }

    if (!this.$store.state.isInMainMenu && !this.isCurrentActiveRoute) {
      v += "menu-item__unselected";
    }

    return v;
  }

  onItemClick() {
    let backupTransition = this.$refs.menuItem.style.transition;
    this.$refs.menuItem.style.transition = "all 130ms";
    this.$refs.menuItem.style.transform = "scale(1)";
    setTimeout(() => {
      this.$refs.menuItem.style.transition = backupTransition;
      this.$store.dispatch("setActiveItemBounds", this.$refs.menuItem);
      if (!this.isCurrentActiveRoute) {
        this.$router.push(this.item.to);
      }
    }, 150);
  }
}
