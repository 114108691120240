









































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ArrowButton extends Vue {
  @Prop({ default: false })
  right!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: "#FFFFFF" })
  strokeColor!: string;

  onClick($event: any) {
    if (this.disabled) {
      return;
    }
    this.$emit("click", $event);
  }
}
