




































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SubMenuItem } from "@/components/SubMenu/sub-menu-item.model";

@Component
export default class OsNavigationIndicator extends Vue {
  @Prop()
  items!: SubMenuItem[];

  @Prop({ required: true })
  activeItemIndex!: number;

  activeItemInternal: number = -1;

  mounted() {
    this.activeItemInternal = this.activeItemIndex;
  }

  @Watch("activeItemIndex")
  onActiveItemIndexChanged() {
    this.activeItemInternal = this.activeItemIndex;
  }
}
