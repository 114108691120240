





































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeProjectsDatabase extends Vue {
  headerText: string = "PostgreSQL Datenbank";
  subHeaderText: string = "";
}
