var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-layout-root"},[(_vm.$store.state.breakpoint.mdAndUp)?_c('div',{staticClass:"header"},[_c('os-close-button',{staticClass:"close-button",attrs:{"size":20,"to":_vm.backTo}})],1):_vm._e(),_c('div',{class:[
      !_vm.absoluteBottom ? 'sub-menu-layout--bottom-absolute' : '',
      _vm.$store.state.breakpoint.smAndDown ? 'mobile' : ''
    ]},[_c('div',{staticClass:"content",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : '']},[_c('transition',{attrs:{"name":_vm.slotTransition,"mode":"out-in"}},[(
            _vm.internalItems.length > 0 &&
              !!_vm.internalItems[0].imageNavigationImageUrl
          )?_c('os-image-navigation',{attrs:{"items":_vm.internalItems,"active-item-index":_vm.activeItemInternal,"navigate-by-internal-navigation":_vm.navigateByInternalNavigation},on:{"update:activeItemIndex":function($event){_vm.activeItemInternal=$event},"update:active-item-index":function($event){_vm.activeItemInternal=$event},"update:navigateByInternalNavigation":function($event){_vm.navigateByInternalNavigation=$event},"update:navigate-by-internal-navigation":function($event){_vm.navigateByInternalNavigation=$event}}}):_vm._e()],1),(_vm.slotTransition !== 'none')?[_c('transition',{attrs:{"name":_vm.slotTransition,"mode":"out-in"}},[_vm._t("default")],2)]:[_vm._t("default")]],2),(_vm.displayBottom)?_c('div',{staticClass:"bottom",class:[
        _vm.$store.state.breakpoint.smAndDown ? 'mobile' : '',
        _vm.absoluteBottom && _vm.$store.state.breakpoint.mdAndUp
          ? 'bottom--absolute'
          : 'bottom--relative'
      ]},[(
          !!_vm.internalItems &&
            _vm.internalItems.length > 1 &&
            !_vm.$store.state.isInSubMenu
        )?_c('div',{staticClass:"bottom--navigation"},[_c('arrow-button',{attrs:{"disabled":_vm.isFirstItem,"strokeColor":"#1A1A1A"},on:{"click":_vm.previousItem}}),_c('os-navigation-indicator',{attrs:{"items":this.internalItems.filter(function (item) { return !!item.to && item.to.length > 0; }),"active-item-index":_vm.activeItemInternal},on:{"update:activeItemIndex":function($event){_vm.activeItemInternal=$event},"update:active-item-index":function($event){_vm.activeItemInternal=$event}}}),_c('arrow-button',{attrs:{"disabled":_vm.isLastItem,"right":"true","strokeColor":"#1A1A1A"},on:{"click":_vm.nextItem}})],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }