






































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeClientsTestimonialsRudyWinklhofer extends Vue {
  headerText: string = "Rudy Winklhofer";
  subHeaderText: string = "Creative Director, Die Jäger von Röckersbühl";
}
