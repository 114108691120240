var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro no-select",class:{
    show: _vm.internalVisible
  }},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('h3',[_vm._v("Über uns:")]),_c('div',{staticClass:"pointer",style:({
        height: '22px',
        width: '22px',
        'margin-top': '12px'
      }),on:{"click":_vm.onCloseClicked}},[_c('svg',{style:({
          height: '20px',
          width: '20px'
        }),attrs:{"xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{staticStyle:{"stroke-width":"1"},attrs:{"x1":20,"y1":"0","x2":"0","y2":20,"stroke":"white"}}),_c('line',{staticStyle:{"stroke-width":"1"},attrs:{"x1":"0","y1":"0","x2":20,"y2":20,"stroke":"white"}})])])]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"intro--body"},[_vm._v(" Unser Team aus Fullstack Ent­wick­lern analysiert deine Problem­stellung und ent­wickelt für dein Unter­nehmen eine perfekt ab­ge­stimmte Soft­ware­lö­sung, die all deinen An­for­der­ungen ent­spricht und dabei ein Höchstmaß an Qualität ga­ran­tiert."),_c('br'),_c('br'),_vm._v(" Mit unseren Standorten in Ober­haching bei München und im Technologie-Campus Pars­berg (zwischen Nürnberg und Regensburg) unter­stützen wir kleine und mittel­ständische Un­ter­neh­men, Startups und große Konzerne bei ihren Soft­wareprojekten."),_c('br'),_c('br'),_vm._v(" Egal ob du Software für das Web, eine mobile App, Webservices oder Datenbanken im Backend benötigst, wir können mit unserem Know-How alle Bereiche der modernen Softwareentwicklung abdecken und verwenden dabei state-of-the-art Tools und Techniken wie z.B. Azure, Kubernetes, Docker, Vue.js, .Net Core uvm."),_c('br')])}]

export { render, staticRenderFns }