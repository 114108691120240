

























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class DefaultContentLayout extends Vue {
  @Prop({ default: "" })
  imageUrl!: string;

  @Prop({ default: "" })
  headerText!: string;

  @Prop({ default: "" })
  subHeaderText!: string;

  @Prop({ default: "90px" })
  imageHeight!: string;

  @Prop({ default: "90px" })
  imageWidth!: string;

  @Prop({ default: "header" })
  imageAlt!: string;

  $refs!: {
    headerContainer: HTMLDivElement;
    contentContainer: HTMLDivElement;
    gradientContainer: HTMLDivElement;
  };

  mounted() {
    this.recalculateHeights();
  }

  @Watch("$store.state.breakpoint.smAndDown")
  @Watch("$store.state.windowInnerHeight")
  @Watch("$store.state.windowInnerWidth")
  @Watch("$store.state.activeSubMenuItem")
  recalculateHeights() {
    this.setBodyHeight();
    this.setGradientHeight();
  }

  setBodyHeight() {
    if (this.$store.state.breakpoint.smAndDown) {
      this.$refs.contentContainer.style.height = "unset";
      return;
    }

    let windowInnerHeight =
      this.$store.state.windowInnerHeight >
      this.$store.state.ContentSiteMinHeight
        ? this.$store.state.windowInnerHeight
        : this.$store.state.ContentSiteMinHeight;

    let imageNavigation = this.$store.state.activeSubMenuItem
      ? this.$store.state.activeSubMenuItem.imageNavigationImageUrl
        ? 200
        : 0
      : 0;
    // 235: top-navigation + bottom-navigation + all whitespaces between
    let navigationAndHeader =
      235 + imageNavigation + this.$refs.headerContainer.offsetHeight;

    this.$refs.contentContainer.style.height =
      windowInnerHeight - navigationAndHeader + "px";
  }

  setGradientHeight() {
    if (!this.$store.state.breakpoint.mdAndUp) {
      return;
    }

    let contentContainer = this.$refs.contentContainer;

    if (!contentContainer) {
      this.$refs.gradientContainer.style.height = "100px";
      return;
    }
    let scrollBottom =
      contentContainer.scrollHeight -
      contentContainer.offsetHeight -
      contentContainer.scrollTop;
    if (scrollBottom < 75) {
      this.$refs.gradientContainer.style.height = scrollBottom + "px";
    } else {
      this.$refs.gradientContainer.style.height = "75px";
    }
  }
}
