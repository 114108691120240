












import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class OsCheckbox extends Vue {
  @Prop({ default: false })
  value!: boolean;

  internalInput: boolean = false;

  @Watch("internalInput")
  onInternalInputChanged() {
    this.$emit("input", this.internalInput);
  }

  mounted() {
    this.internalInput = this.value;
  }
}
