











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OsButton extends Vue {
  @Prop()
  label!: string;
  @Prop({ default: false })
  disabled!: boolean;
}
