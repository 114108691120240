




















import { Component, Vue, Watch } from "vue-property-decorator";
import ContentViewLayout from "@/layouts/ContentViewLayout.vue";
import { SubMenuItem } from "@/components/SubMenu/sub-menu-item.model";
import SubMenu from "@/components/SubMenu/SubMenu.vue";
@Component({
  components: { SubMenu, ContentViewLayout }
})
export default class HomeProjectsIndex extends Vue {
  items: SubMenuItem[] = this.$store.state.mainMenuItems.find(
    (mainMenuItem: any) => mainMenuItem.to === "/projects"
  ).subMenu;

  selectedItem: number = 0;

  mounted() {
    this.onRouteChanged();
  }

  @Watch("$route")
  onRouteChanged() {
    this.$store.commit(
      "setIsInSubMenu",
      this.$router.currentRoute.name === "home_projects"
    );
  }
}
