

















































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeProjectsAntes extends Vue {
  headerText: string = "ANTES Profile - Vue.js Webplattform";
  subHeaderText: string = "(ANTES Solutions GmbH)";
}
