































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import OsCloseButton from "@/components/OsCloseButton.vue";
@Component({
  components: { OsCloseButton }
})
export default class extends Vue {
  @Prop({ default: false })
  visible!: boolean;
  internalVisible: boolean = false;

  @Watch("visible")
  onVisibleChanged() {
    this.internalVisible = this.visible;
  }

  mounted() {
    this.internalVisible = this.visible;
  }

  onCloseClicked() {
    this.$emit("update:visible", false);
  }
}
