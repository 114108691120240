




































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ContentViewLayout from "@/layouts/ContentViewLayout.vue";
import OsButton from "@/components/OsButton.vue";
import OsSocialButton from "@/components/OsSocialButton.vue";
import { ContactRequest, ContactService } from "@/connectors";
import Index from "@/views/Home/Index.vue";
import OsCheckbox from "@/components/OsCheckbox.vue";
import OsLoadingIndicator from "@/components/OsLoadingIndicator.vue";
import OsCheckCircle from "@/components/OsCheckCircle.vue";
@Component({
  components: {
    OsCheckCircle,
    OsLoadingIndicator,
    OsCheckbox,
    Index,
    OsSocialButton,
    OsButton,
    ContentViewLayout
  }
})
export default class HomeContactMailEnquiry extends Vue {
  contactRequest: ContactRequest = new ContactRequest();
  acceptedPrivacyPolicy: boolean = false;

  loading: boolean = false;
  messageSent: boolean = false;
  messageError: boolean = false;
  vueRecaptchaEnabled: boolean = false;
  $refs!: {
    recaptcha: any;
  };

  beforeDestroy() {
    this.vueRecaptchaEnabled = false;
  }

  enableVueRecaptcha() {
    if (this.vueRecaptchaEnabled == false) {
      this.vueRecaptchaEnabled = true;
    }
  }

  get buttonEnabled() {
    return (
      this.acceptedPrivacyPolicy &&
      this.contactRequest.email.length > 0 &&
      this.contactRequest.name.length > 0 &&
      this.contactRequest.message.length > 0 &&
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        this.contactRequest.email
      )
    );
  }

  get textareaBodyHeight(): string {
    if (this.$store.state.breakpoint.smAndDown) {
      return "unset";
    }
    return this.$store.state.windowInnerHeight * 0.2 + "px";
  }

  async onCaptchaVerified(recaptchaToken: string) {
    this.contactRequest.captchaToken = recaptchaToken;
    await this.sendMessageRequest();
  }

  onCaptchaExpired() {
    this.loading = false;
    this.contactRequest.captchaToken = null;
    this.$refs.recaptcha.reset();
  }

  async sendMessage() {
    this.loading = true;

    if (this.contactRequest.captchaToken !== null) {
      await this.sendMessageRequest();
    } else {
      this.$refs.recaptcha.execute();
    }
  }

  async sendMessageRequest() {
    try {
      const result = await ContactService.sendContactRequest(
        this.contactRequest
      );
      if (result && result.success) {
        this.messageSent = true;
      } else {
        this.messageError = true;
      }
    } finally {
      this.loading = false;
    }
  }

  openUrl(url: string) {
    window.open(url, "_blank");
  }
}
