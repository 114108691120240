











































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MainMenuItemModel } from "@/components/MainMenu/main-menu-item.model";
import MainMenuItem from "@/components/MainMenu/MainMenuItem.vue";
import ArrowButton from "@/components/ArrowButton.vue";
import animejs from "animejs";
import config from "@/utils/global-config";
import OsLegalInformation from "@/components/OsLegalInformation.vue";
import smoothscroll from "smoothscroll-polyfill";
import OsCloseButton from "@/components/OsCloseButton.vue";
import OsMainPageIntroDialog from "@/components/OsMainPageIntroDialog.vue";

@Component({
  components: {
    OsMainPageIntroDialog,
    OsCloseButton,
    ArrowButton,
    MainMenuItem,
    OsLegalInformation
  }
})
export default class MainMenu extends Vue {
  @Prop({
    required: true
  })
  items!: MainMenuItemModel[];

  isIntroOpen: boolean = false;
  previousScrollOffset: number = 0;

  get itemHeight(): number {
    if (this.$store.state.windowInnerHeight <= 300) {
      return this.$store.state.windowInnerHeight / 1.8;
    } else if (this.$store.state.windowInnerHeight <= 400) {
      return this.$store.state.windowInnerHeight / 1.7;
    } else if (this.$store.state.windowInnerHeight <= 650) {
      return this.$store.state.windowInnerHeight / 1.55;
    } else if (this.$store.state.windowInnerHeight >= 1200) {
      return this.$store.state.windowInnerHeight / 1.3;
    }
    return this.$store.state.windowInnerHeight / 1.4;
  }

  previousItemBtnActive: boolean = false;
  nextItemBtnActive: boolean = false;

  $refs!: {
    itemsContainer: HTMLDivElement;
    rootContainer: HTMLDivElement;
  };

  onScrollWheel(e: WheelEvent) {
    let delta = Math.max(-1, Math.min(1, -e.deltaY || -e.detail));
    this.$refs.itemsContainer.scrollLeft -=
      (delta * (window.innerHeight * 0.7)) / 2;
    e.preventDefault();
  }

  @Watch("$store.state.isInMainMenu")
  onIsInMainMenuChanged(v: boolean) {
    if (v) {
      setTimeout(() => {
        this.$refs.itemsContainer.style.scrollBehavior = "unset";
        if (this.$store.state.windowDimensionChanged) {
          this.$refs.itemsContainer.scrollLeft = 0;
        } else {
          this.$refs.itemsContainer.scrollLeft = this.previousScrollOffset;
        }
        this.$refs.itemsContainer.style.scrollBehavior = "smooth";
        this.onScroll(); // recalc scroll if navigated back to MainMenu.
      }, 0);
    }
  }

  onScroll() {
    if (!this.$refs.itemsContainer) {
      return;
    }

    this.nextItemBtnActive =
      this.$refs.itemsContainer.scrollWidth -
        this.$refs.itemsContainer.offsetWidth -
        25 >=
      this.$refs.itemsContainer.scrollLeft;

    this.previousItemBtnActive = this.$refs.itemsContainer.scrollLeft > 0;
    this.previousScrollOffset = this.$refs.itemsContainer.scrollLeft;
  }

  nextItem() {
    this.$refs.itemsContainer!.scrollTo({
      left: this.$refs.itemsContainer.scrollLeft + this.itemHeight / 1.4 + 26,
      behavior: "smooth"
    });
  }

  get itemPositions(): number[] {
    let itemsWidth = this.itemHeight / 1.4 + 26;
    return [0, itemsWidth, itemsWidth * 2, itemsWidth * 3];
  }

  previousItem() {
    let nextLeftScrollPoint = 0;
    const scrollLeftRoundedDown = Math.floor(
      this.$refs.itemsContainer.scrollLeft
    );
    for (let i = 0; i < this.itemPositions.length; i++) {
      if (this.itemPositions[i] < scrollLeftRoundedDown) {
        nextLeftScrollPoint = this.itemPositions[i];
      }
    }

    this.$refs.itemsContainer!.scrollTo({
      left: nextLeftScrollPoint,
      behavior: "smooth"
    });
  }

  mounted() {
    // todo: move polyfill to main.ts or import as own plugin. -> ask NH :-)
    smoothscroll.polyfill();
    this.onScroll();
    if (
      this.$store.state.activeItem !== null &&
      this.$store.state.activeItem.menuItem !== null
    ) {
      this.$store.state.activeItem.menuItem.visible = true;
    }

    // fix firefox bug ?!
    setTimeout(() => {
      this.onScroll();
    }, 500);

    setTimeout(() => {
      this.onScroll();
    }, 1500);
  }

  goHome() {
    if (!this.$store.state.isInMainMenu) {
      this.$router.push("/");
    }
  }

  itemActiveBeforeEnter(el: HTMLElement) {
    // this.showActiveItemElements = false;
    this.$store.commit("setWindowDimensionChanged", false);

    this.$store.state.activeItem.lastVisibleMenuItem.visible = true;

    if (this.$store.state.breakpoint.smAndDown) {
      animejs({
        targets: el,
        translateY: -420,
        duration: 0
      });
      return;
    }

    el.style.left = 0 + "px";
    el.style.top = 0 + "px";
    el.style.width = 50 + "%";
    el.style.height = 100 + "%";

    const viewHeight = window.innerHeight;
    const viewWidth = window.innerWidth / 2;
    const bounds = this.$store.state.activeItem.bounds;

    const deltaHeight = bounds.height / viewHeight;
    const deltaWidth = bounds.width / viewWidth;

    const deltaBottom = ((viewHeight - bounds.height) / 2 - bounds.top) * -1;

    const deltaLeft = bounds.left;

    animejs({
      targets: el,
      translateX: deltaLeft,
      translateY: deltaBottom,
      scaleX: deltaWidth,
      scaleY: deltaHeight,
      duration: 0
    });
  }

  async itemActiveEnter(el: HTMLElement, done: any) {
    await this.$store.commit("setIsAnimating", true);

    // this.showActiveItemElements = true;

    if (this.$store.state.breakpoint.smAndDown) {
      animejs({
        targets: el,
        translateY: 0,
        duration: config.animationDuration,
        easing: config.easing,
        complete: async () => {
          done();
          await this.$store.commit("setIsAnimating", false);
          await this.$store.commit("setIsInMainMenuHover", false);
        }
      });

      return;
    }

    animejs({
      targets: el,
      translateX: 0,
      translateY: 0,
      scaleX: 1,
      scaleY: 1,
      duration: config.animationDuration,
      easing: config.easing,
      complete: async () => {
        done();
        await this.$store.commit("setIsAnimating", false);
        await this.$store.commit("setIsInMainMenuHover", false);
      }
    });
  }

  itemActiveBeforeLeave(el: HTMLElement) {
    if (this.$store.state.breakpoint.smAndDown) {
      return;
    }

    // the binding does not update the ui...
    if (el.getElementsByTagName("h1")[0]) {
      el.getElementsByTagName("h1")[0].innerText = "";
    }

    this.$store.state.activeItem.lastVisibleMenuItem.visible = true;

    const viewHeight = window.innerHeight;
    const viewWidth = window.innerWidth / 2;
    const bounds = this.$store.state.activeItem.bounds;

    if (!bounds || this.$store.state.windowDimensionChanged) {
      return;
    }

    const deltaHeight = viewHeight / bounds.height;
    const deltaWidth = viewWidth / bounds.width;

    const deltaLeft = bounds.left * -1;

    el.style.left = bounds.left + "px";
    el.style.top = bounds.top + "px";
    el.style.width = this.itemHeight / 1.4 + "px";
    el.style.height = this.itemHeight + "px";

    animejs({
      targets: el,
      translateX: deltaLeft,
      scaleX: deltaWidth,
      scaleY: deltaHeight,
      duration: 0,
      easing: "linear"
    });
  }

  async itemActiveLeave(el: HTMLElement, done: any) {
    await this.$store.commit("setIsAnimating", true);
    this.$store.state.activeItem.lastVisibleMenuItem.visible = true;

    if (this.$store.state.breakpoint.smAndDown) {
      animejs({
        targets: el,
        translateY: -420,
        easing: config.easing,
        duration: config.animationDuration,
        complete: async () => {
          done();

          //  this.showActiveItemElements = false;
          this.$store.state.activeItem.lastVisibleMenuItem.visible = false;
          await this.$store.commit("setIsAnimating", false);
          await this.$store.commit("setIsInMainMenuHover", false);
        }
      });
      return;
    }

    let animationDuration = config.animationDuration;

    const bounds = this.$store.state.activeItem.bounds;

    if (!bounds || this.$store.state.windowDimensionChanged) {
      animationDuration = 0;
    }

    animejs({
      targets: el,
      translateX: 0,
      scaleX: 1,
      scaleY: 1,
      duration: animationDuration,
      easing: config.easing,
      complete: async () => {
        done();

        // this.showActiveItemElements = false;
        this.$store.state.activeItem.lastVisibleMenuItem.visible = false;
        await this.$store.commit("setIsAnimating", false);
        await this.$store.commit("setIsInMainMenuHover", false);
        this.$store.commit("setWindowDimensionChanged", false);
      }
    });
  }

  onFooterClicked() {
    if (this.$store.state.breakpoint.mdAndUp) {
      this.isIntroOpen = true;
    }
  }
}
