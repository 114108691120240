import { render, staticRenderFns } from "./MartinDess.vue?vue&type=template&id=52079b71&scoped=true&"
import script from "./MartinDess.vue?vue&type=script&lang=ts&"
export * from "./MartinDess.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52079b71",
  null
  
)

export default component.exports