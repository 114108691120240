




























import { Component, Vue } from "vue-property-decorator";

@Component
export default class OsInfoFooter extends Vue {
  toTop() {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  openUrl(url: string) {
    window.open(url, "_blank");
  }
}
