
















































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeProjectsSellvision extends Vue {
  headerText: string = "DETAIL Business Information GmbH";
  subHeaderText: string = "archipinion by DETAIL";
}
