import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// styles
import "@/styles/globals.scss";

// plugins
import "./plugins/axios";
import "./plugins/vue-gtag";
import "./plugins/vue-recaptcha";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
