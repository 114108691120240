

















































import { Component, Prop, Vue } from "vue-property-decorator";
import { SubMenuItem } from "@/components/SubMenu/sub-menu-item.model";

@Component
export default class SubMenu extends Vue {
  @Prop({ required: true })
  items!: SubMenuItem[];

  @Prop()
  activeItem!: number;

  navigate(index: number, to: string) {
    if (to.length > 0) {
      this.$emit("update:activeItem", index);
      this.$router.push(to);
    }
  }
}
