import Vue from "vue";
import VueGtag from "vue-gtag";
import router from "./../router";

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-130626710-2",
      params: {
        anonymize_ip: true
      }
    },
    bootstrap: false
  },
  router
);
