




















































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeProjectsHeimatInfo extends Vue {
  headerText: string = "Heimat-Info - Flutter App & Web";
  subHeaderText: string = "(Cosmema GmbH)";
}
