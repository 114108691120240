import Vue from "vue";
import { Route, RouteConfig } from "vue-router/types/router";
import VueRouter from "vue-router";
import store from "../store";
import HomeIndex from "../views/Home/Index.vue";
import LegalNotice from "@/views/LegalNotice.vue";
import OhNapBabySleepAidPrivacyPolicy from "@/views/Apps/OhSnapBabySleep/PrivacyPolicy.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import { HomeContactIndex } from "@/views/Home/Contact";

import {
  HomeProjectsIndex,
  HomeProjectsHeimatInfo,
  HomeProjectsAntes,
  HomeProjectsApochannel,
  HomeProjectsSellvision,
  HomeProjectsProjectCommunity,
  HomeProjectsProjectDatabase
} from "@/views/Home/Projects";

import { HomeClientsIndex } from "@/views/Home/Clients";

import {
  HomeClientsCustomersIndex,
  HomeClientsCustomersMarpinion,
  HomeClientsCustomersSellvision,
  HomeClientsCustomersDetail,
  HomeClientsCustomersCosmo,
  HomeClientsCustomersAntes,
  HomeClientsCustomersCosmema
} from "@/views/Home/Clients/Customers";

import {
  HomeClientsPartnersIndex,
  HomeClientsPartnersPartnerCosmo,
  HomeClientsPartnersPartnerSellvision,
  HomeClientsPartnersPartnerTechnologiezentrum,
  HomeClientsPartnersPartnerEasyConsult
} from "@/views/Home/Clients/Partners";

import {
  HomeClientsTestimonialsIndex,
  HomeClientsTestimonialsRudyWinklhofer,
  HomeClientsTestimonialsJuliaPueltz,
  HomeClientsTestimonialsMartinDess,
  HomeClientsTestimonialsMichaelHabla,
  HomeClientsTestimonialsRomanBachler,
  HomeClientsTestimonialsStefanJaeger,
  HomeClientsTestimonialsChristophWoehrl,
  HomeClientsTestimonialsSabineBeitel,
  HomeClientsTestimonialsJoschaSielemann
} from "@/views/Home/Clients/Testimonials";

import {
  HomePortfolioIndex,
  HomePortfolioSoftware,
  HomePortfolioMobile,
  HomePortfolioBackend,
  HomePortfolioFrontend,
  HomePortfolioTraining,
  HomePortfolioMission
} from "@/views/Home/Portfolio";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: HomeIndex,
    children: [
      {
        path: "projects",
        name: "home_projects",
        component: HomeProjectsIndex,
        meta: {
          title: "Projekte | Entwicklung von Individualsoftware",
          description:
            "Schnelle und qualitativ hochwertige Umsetzung von Software Projekten. Vom Konzept zum MVP."
        },
        children: [
          {
            path: "apochannel",
            name: "home_project_apochannel",
            component: HomeProjectsApochannel,
            meta: {
              title: "ApoChannel | Mobile Wissensplattform für Apotheker",
              description:
                "Key-Features: Biometrische Gesichtserkennung, HLS Videostreaming, E-Learning, Eigenes Content Management System zum Erstellen von Fragebögen und Hochladen von Videos zu AWS, AWS Elastic Transcoding für S3 zum automatischen Konvertieren von Videos"
            }
          },
          {
            path: "heimat-info",
            name: "home_project_heimatinfo",
            component: HomeProjectsHeimatInfo,
            meta: {
              title: "Heimat-Info | App für Kommunen",
              description:
                "Web-Applikation für Bürger & Kommunen, CMS und Administrations-UI für die Mitarbeiter & Apps für iOS und Android in Flutter."
            }
          },
          {
            path: "antes",
            name: "home_project_antes",
            component: HomeProjectsAntes,
            meta: {
              title: "ANTES Profile | Webplattform für Bewerberanalysen",
              description:
                "Firmen können ihren Bewerbern links zur Verfügung stellen über diesen sie einen Fragebogen ausfüllen können. Die Ergebnisse werden aufbereitet und den Unternehmen als Pdf-Datei zur Verfügung gestellt."
            }
          },
          {
            path: "sellvision",
            name: "home_project_sellvision",
            component: HomeProjectsSellvision,
            meta: {
              title: "SellVision | Web Video Chat & Salestool",
              description:
                "Die Applikation wurde als .NET Core Webapp mit Vue.js entwickelt und funktioniert sowohl im Desktopbrowser, als auch responsive auf mobilen Geräten. Über das SignalR Framework bauen wir eine synchrone, gesicherte und verschlüsselte peer-to-peer Verbindung zwischen den Parteien auf."
            }
          },
          {
            path: "community",
            name: "home_project_community",
            component: HomeProjectsProjectCommunity,
            meta: {
              title:
                "Pitchry | Social Community Platform, Machine Learning & KI",
              description:
                "Die Applikation wurde mit Vue.js und vuetify entwickelt und beinhaltet per Machine Learning gesteuerte Komponenten, deren Logik und neuronale Netze ebenfalls von uns erstellt wurden."
            }
          },
          {
            path: "database",
            name: "home_project_database",
            component: HomeProjectsProjectDatabase,
            meta: {
              title: "PostgreSQL Datenbank | Hosted in Azure Kubernetes",
              description:
                "In diesem Projekt haben wir ein komplexes Datenmodell in einer PostgreSQL Datenbank abgebildet, die in einem Dockercontainer gehostet wurde und die Daten persistent außerhalb des Containers speichert."
            }
          }
        ]
      },
      {
        path: "portfolio",
        name: "home_portfolio",
        component: HomePortfolioIndex,
        meta: {
          title: "Portfolio | Software, Apps & Infrastruktur",
          description:
            "Beratung, Konzeption und Umsetzung von Softwareprojekten. Apps (Apple iOS und Android), Webapplikationen, Backend-Services und Infrastruktur."
        },
        children: [
          {
            path: "software",
            name: "home_portfolio_software",
            component: HomePortfolioSoftware,
            meta: {
              title: "Software made in Parsberg & Oberhaching bei München",
              description:
                "Du bist auf der Suche nach individuellen Lösungen, benötigst eine App für mobile Endgeräte oder einen Experten für moderne Software- und Webentwicklung? Bei uns bist du richtig!"
            }
          },
          {
            path: "mobile",
            name: "home_portfolio_mobile",
            component: HomePortfolioMobile,
            meta: {
              title:
                "Mobile App Entwicklung | Android, Apple iOS & Cross-Platform",
              description:
                "Egal ob Android, Apple iOS App oder Cross-Plattform – wir entwickeln die passende mobile Lösung für dein Unternehmen. Dank unserer Philosophie, die Businesslogik nicht innerhalb einer App, sondern über Webservices im Hintergrund in einer Microservice-Struktur abzubilden, erreichen wir, dass ca. 90% des Codes wiederverwendet werden kann. Viele Updates und Verbesserungen können serverseitig durchgeführt werden, ohne die App in einen der Stores einliefern zu müssen."
            }
          },
          {
            path: "backend",
            name: "home_portfolio_backend",
            component: HomePortfolioBackend,
            meta: {
              title: "Backend & Cloud | Azure, AWS, Docker & Kubernetes",
              description:
                "Gut durchdachte Webservices, Datenbanken und Schnittstellen zu bestehenden Systemen bilden das Fundament für eine stabile Software. Wir planen und bauen Software Architekturen, die perfekt aufeinander abgestimmt sind und dabei skalierbar bleiben, um auch künftigen Anforderungen Stand zu halten."
            }
          },
          {
            path: "frontend",
            name: "home_portfolio_frontend",
            component: HomePortfolioFrontend,
            meta: {
              title: "Web & Frontend | HTML, Javascript, Vue.js & Angular",
              description:
                "Wir entwickeln professionelle Webapplikationen unter Einsatz von Komponenten, die sich gut supporten lassen, wie Vue.js oder Angular. Responsive Design steht dabei dank unserer Philosophie mobile first schon während der Konzeption stets im Vordergrund."
            }
          },
          {
            path: "training",
            name: "home_portfolio_training",
            component: HomePortfolioTraining,
            meta: {
              title:
                "Schulung & Coaching | Software Know-How aus München und der Oberpfalz",
              description:
                "Dich interessieren moderne Softwarepatterns in der täglichen Anwendung oder ein einheitlicher Devops Prozess? Du möchtest mehr zum Thema Container & Docker oder Xamarin App Development wissen? Dann melde dich bei uns!"
            }
          },
          {
            path: "mission",
            name: "home_portfolio_mission",
            component: HomePortfolioMission,
            meta: {
              title: "Unsere Mission | Moderne Software & hohe Qualität",
              description:
                "Unsere Kunden schätzen es sehr, dass wir in den Projekten stets mitdenken und über den Tellerrand hinaus schauen. Zusammen mit maximaler Performance bei höchsten Qualitätsansprüchen schaffen wir es, einen bleiebenden Eindruck zu hinterlassen.."
            }
          }
        ]
      },
      {
        path: "clients",
        name: "home_clients",
        component: HomeClientsIndex,
        meta: {
          title: "Clients | Unsere Partner & Kunden",
          description: "Was sagen unserer Kunden und Partner über uns?"
        },
        children: [
          {
            path: "testimonials",
            component: HomeClientsTestimonialsIndex,
            children: [
              {
                path: "rudywinklhofer",
                name: "home_clients_testimonials_rudywinklhofer",
                component: HomeClientsTestimonialsRudyWinklhofer,
                meta: {
                  title: "Rudy Winklhofer",
                  description:
                    "Creative Director, Die Jäger von Röckersbühl - Einerseits sind sie beide eingefleischte Programmierer, andererseits sehr User-orientierte und pragmatisch-konzeptionelle Denker."
                }
              },
              {
                path: "juliapueltz",
                name: "home_clients_testimonials_juliapueltz",
                component: HomeClientsTestimonialsJuliaPueltz,
                meta: {
                  title: "Julia Pültz",
                  description:
                    "Director of Operations - archipinion by DETAIL, DETAIL Business Information GmbH - Ganz ehrlich, die Zusammenarbeit mit oh snap! funktioniert wirklich außergewöhnlich gut, das dürfen ruhig alle wissen!"
                }
              },
              {
                path: "martindess",
                name: "home_clients_testimonials_martindess",
                component: HomeClientsTestimonialsMartinDess,
                meta: {
                  title: "Martin Dess",
                  description:
                    "Geschäftsführer & Gesellschafter, dessign group - Ich kenne Nico und Dennis nun schon seit einigen Jahren und arbeite total gerne mit ihnen zusammen. Bei ihnen kommen zwei Komponenten zusammen, die ich als Unternehmer als sehr wichtig empfinde: Sie verbinden absolute Fachkompetenz mit totaler Sympathie. Mit solchen Menschen zusammenzuarbeiten ist das Beste was einem passieren kann."
                }
              },
              {
                path: "michaelhabla",
                name: "home_clients_testimonials_michaelhabla",
                component: HomeClientsTestimonialsMichaelHabla,
                meta: {
                  title: "Michael Habla",
                  description:
                    "Managing Director, Aposphäre - Der gesamte Entwicklungsprozess ist gekennzeichnet von einer pragmatischen und lösungsorientierten Herangehensweise sowie der absoluten Offenheit, was den optimalen Einsatz modernster Technologien betrifft."
                }
              },
              {
                path: "romanbachler",
                name: "home_clients_testimonials_romanbachler",
                component: HomeClientsTestimonialsRomanBachler,
                meta: {
                  title: "Roman Bachler",
                  description:
                    "Managing Director, COSMO CONSULT - In der Softwareentwicklung bzw. im Projektmanagement allgemein ist oft vom magischen Dreieck die Rede, um Qualität, Kosten und Zeit auszubalancieren."
                }
              },
              {
                path: "stefanjaeger",
                name: "home_clients_testimonials_stefanjaeger",
                component: HomeClientsTestimonialsStefanJaeger,
                meta: {
                  title: "Stefan Jäger",
                  description:
                    "Operations Manager & Prokurist, COSMO CONSULT - Mit Dennis, Nico und Johannes verbindet mich eine langjährige Zusammenarbeit, die schon immer von Professionalität, Flexibilität, herausragenden Arbeitsergebnissen und vor allem einem freundschaftlich kollegialen Verhältnis geprägt ist."
                }
              },
              {
                path: "sabinebeitel",
                name: "home_clients_testimonials_sabinebeitel",
                component: HomeClientsTestimonialsSabineBeitel,
                meta: {
                  title: "Sabine Beitel",
                  description:
                    "Geschäftsführende Gesellschafterin ANTES Group - Wir hatten Visionen und ein klares Bild davon, was die Applikation am Ende bieten sollte"
                }
              },
              {
                path: "joschasielemann",
                name: "home_clients_testimonials_joschasielemann",
                component: HomeClientsTestimonialsJoschaSielemann,
                meta: {
                  title: "Joscha Sielemann",
                  description:
                    "Geschäftsführer marpinion GmbH - Seit vielen Jahren arbeiten wir erfolgreich mit oh snap! zusammen, und das Team ist für uns ein unverzichtbarer Partner."
                }
              },
              {
                path: "christophwoehrl",
                name: "home_clients_testimonials_christophwoehrl",
                component: HomeClientsTestimonialsChristophWoehrl,
                meta: {
                  title: "Christoph Wöhrl",
                  description:
                    "Geschäftsführung Cosmema GmbH - Die Zusammenarbeit mit oh snap! war eine durchweg positive Erfahrung."
                }
              },
              {
                path: "/",
                name: "home_clients_testimonials_redirect",
                redirect: "rudywinklhofer"
              }
            ]
          },
          {
            path: "customers",
            component: HomeClientsCustomersIndex,
            children: [
              {
                path: "marpinion",
                name: "home_clients_customers_marpinion",
                component: HomeClientsCustomersMarpinion,
                meta: {
                  title: "marpinion GmbH | Software für Oberhaching",
                  description:
                    "Wir betreuen die marpinion GmbH bei der Konzeption und Umsetzung neuer Features, sowie der Optimierung bestehender Prozesse. Unsere Bereiche umfassen die Neuentwicklung und Erweiterung von Webapplikationen, Apps, Webservices und Datenbanken."
                }
              },
              {
                path: "sellvision",
                name: "home_clients_customers_sellvision",
                component: HomeClientsCustomersSellvision,
                meta: {
                  title: "SellVision UG | Software für Regensburg",
                  description:
                    "SellVision unterstützt den Verkauf durch eine eigene Videochat-Software. Ein Verkäufer kann hierüber sein Verkaufsobjekt in höchster Qualität und maximaler Detailtiefe präsentieren. Dabei bleiben die Interessenten immer anonym. Alle Daten werden über Server in Deutschland geroutet und erfüllen die Richtlinien des geltenden deutschen Datenschutzes und der DSGVO."
                }
              },
              {
                path: "detail",
                name: "home_clients_customers_detail",
                component: HomeClientsCustomersDetail,
                meta: {
                  title:
                    "DETAIL Business Information GmbH | Software für München",
                  description:
                    "archipinion by DETAIL hat eine iOS iPad App, eine React WebApp und eine React native App im Einsatz, mit denen sich Architekten Videobeiträge ansehen und sich über die neuesten Entwicklungen der Branche informieren können. Das Feedback der Nutzer wird für jeden Beitrag direkt innerhalb der App abgefragt und der Industrie zur Verfügung gestellt."
                }
              },
              {
                path: "cosmo",
                name: "home_clients_customers_cosmo",
                component: HomeClientsCustomersCosmo,
                meta: {
                  title: "Cosmo Consult SI GmbH | Software für Neumarkt",
                  description:
                    "Wir sind Partner und Dienstleister der Cosmo Consult SI GmbH und unterstützen das Team in Neumarkt in der Oberpfalz bei der Umsetzung ihrer Microsoft Dynamics CRM Kundenprojekte."
                }
              },
              {
                path: "antes",
                name: "home_clients_customers_antes",
                component: HomeClientsCustomersAntes,
                meta: {
                  title: "ANTES Solutions GmbH | Software für Hamburg",
                  description:
                    "Im Auftrag der ANTES Solutions GmbH haben wir die Applikation für die Unternehmen komplett neu konzipiert, designed und implementiert."
                }
              },
              {
                path: "cosmema",
                name: "home_clients_customers_cosmema",
                component: HomeClientsCustomersCosmema,
                meta: {
                  title: "Cosmema GmbH | Software für Ingolstadt",
                  description:
                    "Als Entwickler der Heimat-Info-App arbeiten wir zusammen mit Cosmema GmbH stetig an der Weiter- und Neuimplementierung von Features innerhalb der Mobile Apps und Web Applikationen."
                }
              },
              {
                path: "/",
                name: "home_clients_customers_redirect",
                redirect: "marpinion"
              }
            ]
          },
          {
            path: "partners",
            component: HomeClientsPartnersIndex,
            children: [
              {
                path: "techcampus",
                name: "home_clients_partners_techcampus",
                component: HomeClientsPartnersPartnerTechnologiezentrum,
                meta: {
                  title:
                    "Technologie Campus Parsberg | Heimat für digitale Startups",
                  description:
                    "Mit unserem Büro im Technologiecampus Parsberg sitzen wir Tür an Tür mit den Kollegen vom digitalen Gründerzentrum und können uns in vielen Bereichen gegenseitig unterstützen. So haben wir zum Beispiel die Möglichkeit Schulungs- und Veranstaltungsräume zu nutzen, und können auf ein bayernweites Netzwerk aus Gründern, etablierten Unternehmen und Hochschulen zurückgreifen. Dafür bieten wir unser Know-How für digitale Themen in der Region an und geben unser Wissen in Workshops weiter."
                }
              },
              {
                path: "cosmo",
                name: "home_clients_partners_cosmo",
                component: HomeClientsPartnersPartnerCosmo,
                meta: {
                  title: "Cosmo Consult SI GmbH | Microsoft Dynamics CRM",
                  description:
                    "Mit Cosmo Consult SI GmbH in Neumarkt in der Oberpfalz haben wir einen starken Partner, mit dem wir zusammen große Microsoft Dynamics CRM Projekte realisieren können."
                }
              },
              {
                path: "sellvision",
                name: "home_clients_partners_sellvision",
                component: HomeClientsPartnersPartnerSellvision,
                meta: {
                  title:
                    "SellVision UG | Das mobile Videoverkaufstool für Automobilverkäufer",
                  description:
                    "Dank schneller, iterativer Releasezyklen können wir neue Anforderungen aus den Workshops effektiv implementieren und bekommen das Feedback direkt von den Nutzern. Während sich das Salesteam von SellVision um die Akquise und Nutzergenerierung kümmert, entwickeln wir das Produkt ständig weiter, um ein stabiles und modernes Salestool für die Autohäuser zur Verfügung zu stellen."
                }
              },
              {
                path: "easyconsult",
                name: "home_clients_partners_easyconsult",
                component: HomeClientsPartnersPartnerEasyConsult,
                meta: {
                  title: "easyconsult GmbH | CRM erfolgreich einfach",
                  description:
                    "easyconsult GmbH begleitet Unternehmen von der ersten Planung, über die Implementierung bis hin zum Service in Sachen CRM."
                }
              },
              {
                path: "/",
                name: "home_clients_partners_redirect",
                redirect: "techcampus"
              }
            ]
          }
        ]
      },
      {
        path: "contact",
        name: "home_contact",
        component: HomeContactIndex,
        meta: {
          title:
            "Kontakt | Dein Softwarepartner aus Parsberg & Oberhaching bei München",
          description:
            "Du willst mehr über uns erfahren? Kontaktiere uns über das Formular oder besuche uns in Parsberg oder Oberhaching bei München."
        }
      }
    ]
  },
  {
    path: "/legal-notice",
    name: "legal-notice",
    component: LegalNotice,
    meta: {
      title: "Impressum"
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Datenschutzerklärung"
    }
  },
  {
    path: "/apps/oh-nap-baby-sleep/privacy-policy",
    name: "apps-oh-nap-baby-sleep-privacy-policy",
    component: OhNapBabySleepAidPrivacyPolicy,
    meta: {
      title: "oh nap! The baby sleep aid"
    }
  },
  {
    path: "*",
    name: "home_notFound_redirect",
    redirect: "/"
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to: Route, from: Route, next) => {
  let pageTitlePrefix = "oh snap! GmbH - ";
  let pageTitle = "Software, Entwicklung und Beratung aus München";
  let pageDescription =
    "Wir sind ein junges und modernes Unternehmen für Softwareentwicklung mit Sitz in Oberhaching bei München und Parsberg. Konzept, Design, Programmierung und Deployment aus einer Hand.";

  if (!!to && !!to.meta) {
    if (to.meta.title) {
      pageTitle = to.meta.title;
    }

    if (to.meta.description) {
      pageDescription = to.meta.description;
    }
  }

  document.title = pageTitlePrefix + pageTitle;

  const documentTitleNode = document.querySelector('meta[name="description"]');
  if (documentTitleNode) {
    documentTitleNode.setAttribute("content", pageDescription);
  }

  if (
    !!from &&
    !!from.name &&
    from.name.startsWith("home") &&
    !!from.fullPath &&
    from.fullPath.length > 0
  ) {
    store.commit("setLastVisitedPageWithHome", from.fullPath);
  }
  next();
});

router.afterEach((to: Route, from: Route) => {
  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };
  setTimeout(scrollToTop, 400);
});

export default router;
