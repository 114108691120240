










import { Component, Vue, Watch } from "vue-property-decorator";
import ContentViewLayout from "@/layouts/ContentViewLayout.vue";
import SubMenu from "@/components/SubMenu/SubMenu.vue";
@Component({
  components: { SubMenu, ContentViewLayout }
})
export default class HomeClientsTestimonialsIndex extends Vue {
  mounted() {
    this.onRouteChanged();
  }

  @Watch("$route")
  onRouteChanged() {
    this.$store.commit(
      "setIsInSubMenu",
      this.$router.currentRoute.name === "home_clients_testimonials"
    );
  }
}
