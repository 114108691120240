








































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeProjectsAntes extends Vue {
  headerText: string = "ANTES Solutions GmbH";
  subHeaderText: string =
    "Stellen Sie sich richtig auf - und die passenden Persönlichkeiten ein.";
}
