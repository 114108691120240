var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"rootContainer",staticClass:"root-container no-select"},[_c('img',{staticClass:"logo pointer",attrs:{"src":require("./../../assets/images/logo_white_slogan.svg"),"alt":"Software, Entwicklung und Beratung aus München","title":"Software, Entwicklung und Beratung aus München","draggable":"false"},on:{"click":_vm.goHome}}),_c('transition',{attrs:{"name":"fade-in-only"}},[(_vm.$store.state.isInMainMenu)?_c('div',{staticClass:"items-container-outer",class:[
        _vm.$store.state.breakpoint.smAndDown ? 'mobile' : '',
        _vm.$store.state.isInMainMenu ? 'is-in-main-menu' : '',
        _vm.$store.state.isAnimating ? 'is-animating' : ''
      ]},[_c('div',{ref:"itemsContainer",staticClass:"items-container scroll-snap",attrs:{"id":"items_container"},on:{"scroll":_vm.onScroll}},[_vm._l((_vm.items),function(item,index){return [_c('main-menu-item',{key:index,attrs:{"height":_vm.itemHeight,"item":item}})]})],2)]):_vm._e()]),_c('div',{staticClass:"os-body bottom-information-createdby",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : 'pointer'],on:{"click":_vm.onFooterClicked}},[_vm._v(" oh snap! - Software­entwicklung aus München ")]),(_vm.$store.state.isInMainMenu)?_c('div',{staticClass:"arrow-buttons",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : '']},[_c('arrow-button',{staticClass:"arrow-button--left",attrs:{"disabled":!_vm.previousItemBtnActive},on:{"click":_vm.previousItem}}),_c('arrow-button',{staticClass:"arrow-button--right",attrs:{"right":true,"disabled":!_vm.nextItemBtnActive},on:{"click":_vm.nextItem}})],1):_vm._e(),(_vm.$store.state.isInMainMenu)?_c('os-legal-information',{staticClass:"bottom-information-legal",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : ''],attrs:{"dark-theme":true}}):_vm._e(),_c('transition',{on:{"before-enter":_vm.itemActiveBeforeEnter,"enter":_vm.itemActiveEnter,"leave":_vm.itemActiveLeave,"before-leave":_vm.itemActiveBeforeLeave}},[(!!_vm.$store.state.activeItem.menuItem)?_c('div',{staticClass:"background-image menu-item__active",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : ''],style:({
        'background-image':
          'url(' + _vm.$store.state.activeItem.menuItem.backgroundImage + ')'
      })},[_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.activeItem.menuItem.visible)?_c('h1',{staticClass:"mr-40",class:_vm.$store.state.breakpoint.mdAndUp
              ? 'os-header-main'
              : 'os-header-mobile'},[_vm._v(" "+_vm._s(_vm.$store.state.activeItem.menuItem.title)+" ")]):_vm._e()]),(_vm.$store.state.breakpoint.smAndDown)?_c('os-close-button',{staticClass:"close-button",attrs:{"size":20,"stroke-color":"white"}}):_vm._e()],1):_vm._e()]),_c('os-main-page-intro-dialog',{attrs:{"visible":_vm.isIntroOpen},on:{"update:visible":function($event){_vm.isIntroOpen=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }