



























import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeClientsTestimonialsJuliaPueltz extends Vue {
  headerText: string = "Julia Pültz";
  subHeaderText: string =
    "Director of Operations - archipinion by DETAIL, DETAIL Business Information GmbH";
}
