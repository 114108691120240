































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SubMenuItem } from "@/components/SubMenu/sub-menu-item.model";

@Component
export default class OsImageNavigation extends Vue {
  @Prop()
  items!: SubMenuItem[];

  @Prop({ required: true })
  activeItemIndex!: number;

  @Prop({ required: true })
  navigateByInternalNavigation!: boolean;

  activeItemInternal: number = -1;

  mounted() {
    this.activeItemInternal = this.activeItemIndex;
  }

  @Watch("activeItemIndex")
  onActiveItemIndexChanged() {
    this.activeItemInternal = this.activeItemIndex;
  }

  setActiveItem(index: number) {
    if (this.activeItemInternal !== index) {
      this.activeItemInternal = index;
      this.$emit("update:activeItemIndex", index);
      this.$emit("update:navigateByInternalNavigation", true);
      this.$router.push(this.items[this.activeItemInternal].to);
    }
  }

  get itemHeight() {
    if (this.$store.state.breakpoint.mdAndUp) {
      return this.$store.state.windowInnerWidth < 1132
        ? this.$store.state.windowInnerWidth / 13
        : 90 + "px";
    } else {
      return this.$store.state.windowInnerWidth < 560
        ? this.$store.state.windowInnerWidth / 7
        : 90 + "px";
    }
  }
}
