

















import { Component, Vue } from "vue-property-decorator";
import OsCloseButton from "@/components/OsCloseButton.vue";
@Component({
  components: { OsCloseButton }
})
export default class OsInfoHeader extends Vue {
  goHome() {
    this.$router.push(this.$store.state.lastVisitedPageWithHome);
  }
}
