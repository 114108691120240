






































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OsCloseButton extends Vue {
  @Prop({ default: null })
  to!: string | null;

  @Prop({ default: false })
  goBackToLastPage!: boolean;

  @Prop({ default: "#1A1A1A" })
  strokeColor!: string;

  @Prop()
  size!: number;

  padding: number = 3;

  goBack() {
    if (this.goBackToLastPage) {
      this.$router.push(this.$store.state.lastVisitedPageWithHome);
    } else if (!!this.to && this.to.length > 0) {
      if (this.to === "../") {
        this.goBackInRoute(2);
      } else {
        this.$router.push(this.to);
      }
    } else if (
      !!this.$store.state.activeSubMenuItem &&
      this.$store.state.activeSubMenuItem.skipSubSubMenu
    ) {
      this.goBackInRoute(2);
    } else {
      this.goBackInRoute(1);
    }
  }

  goBackInRoute(steps: number) {
    let route = this.$route.path.replace(/\/$/, "");
    let goBackRoute = route.substring(0, route.lastIndexOf("/"));

    while (steps > 1) {
      goBackRoute = goBackRoute.substring(0, goBackRoute.lastIndexOf("/"));
      steps--;
    }

    this.$router.push(goBackRoute ? goBackRoute : "/"); // '/' -> home
  }
}
