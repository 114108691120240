




























import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeClientsTestimonialsMichaelHabla extends Vue {
  headerText: string = "Michael Habla";
  subHeaderText: string = "Managing Director, Aposphäre";
}
