















































































































import { Component, Vue } from "vue-property-decorator";
import OsInfoHeader from "@/components/OsInfoHeader.vue";
import OsInfoFooter from "@/components/OsInfoFooter.vue";
@Component({
  components: { OsInfoFooter, OsInfoHeader }
})
export default class LegalNotice extends Vue {}
