import Vue from "vue";
import Vuex from "vuex";
import { Route } from "vue-router";
import { MainMenuItemModel } from "@/components/MainMenu/main-menu-item.model";
import { SubMenuItem } from "@/components/SubMenu/sub-menu-item.model";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAnimating: false,
    isAnimatingCount: 0,
    isInMainMenu: true,
    isInSubMenu: false,
    isInMainMenuHover: false,
    currentActiveRoute: null,
    activeItemCenter: { x: 0, y: 0 },
    activeItem: {
      center: { x: 0, y: 0 },
      bounds: null,
      menuItem: null,
      lastVisibleMenuItem: null
    },
    activeSubMenuItem: null,
    windowInnerHeight: 0,
    windowInnerWidth: 0,
    ContentSiteMinHeight: 600,
    breakpoint: {
      smAndDown: false,
      mdAndUp: false
    },
    isTouchDevice: false,
    activeMainMenuItem: null,
    lastVisitedPageWithHome: "/",
    analyticToolsAccepted: false,
    windowDimensionChanged: false,
    mainMenuItems: [
      {
        title: "What we do",
        subTitle: "Web, Mobile, Backend",
        backgroundImage: require("@/assets/images/bg/what_we_do.jpg"),
        to: "/portfolio",
        skipSubMenu: false,
        visible: false,
        subMenu: [
          {
            subMenuTitle: "Unsere Mission",
            subMenuSubtitle: "So ticken wir",
            to: "/portfolio/mission"
          },
          {
            subMenuTitle: "Software Projekte",
            subMenuSubtitle: "Vom Konzept bis zur fertigen Software",
            to: "/portfolio/software"
          },
          {
            subMenuTitle: "Mobile Apps",
            subMenuSubtitle: "iOS, Android, Cross-Plattform",
            to: "/portfolio/mobile"
          },
          {
            subMenuTitle: "Backend & Infrastruktur",
            subMenuSubtitle: "Azure, AWS, SQL & Docker",
            to: "/portfolio/backend"
          },
          {
            subMenuTitle: "Web & Frontend",
            subMenuSubtitle: "Vue.js, Typescript, Responsive Design",
            to: "/portfolio/frontend"
          },
          {
            subMenuTitle: "Schulungen & Coachings",
            subMenuSubtitle: "Erfahrung teilen",
            to: "/portfolio/training"
          }
        ]
      },
      {
        title: "What we already did",
        subTitle: "Software­projekte",
        backgroundImage: require("@/assets/images/bg/what_we_already_did.jpeg"),
        to: "/projects",
        skipSubMenu: false,
        visible: false,
        subMenu: [
          {
            subMenuTitle: "ApoChannel",
            subMenuSubtitle: "Restful API & CMS",
            to: "/projects/apochannel"
          },
          {
            subMenuTitle: "Heimat-Info",
            subMenuSubtitle: "iOS, Android & Web",
            to: "/projects/heimat-info"
          },
          {
            subMenuTitle: "ANTES Profile",
            subMenuSubtitle: "Webplattform & PDF-Rendering",
            to: "/projects/antes"
          },
          {
            subMenuTitle: "SellVision",
            subMenuSubtitle: "Peer to peer Video Chat & Salestool",
            to: "/projects/sellvision"
          },
          {
            subMenuTitle: "Pitchry",
            subMenuSubtitle: "Social Media & Machine Learning",
            to: "/projects/community"
          },
          {
            subMenuTitle: "PostgreSQL Database",
            subMenuSubtitle: "Datenbanksecurity in Docker Containern",
            to: "/projects/database"
          },
          {
            subMenuTitle: "And many more...",
            subMenuSubtitle: "",
            to: ""
          }
        ]
      },
      {
        title: "Our clients love us",
        subTitle: "Kunden und Partner",
        backgroundImage: require("@/assets/images/bg/our_clients_love_us.jpeg"),
        to: "/clients",
        skipSubMenu: false,
        visible: false,
        subMenu: [
          {
            subMenuTitle: "Menschen",
            subMenuSubtitle: "Das sagen unsere Kunden",
            to: "/clients/testimonials",
            subSubMenu: [
              {
                subMenuTitle: "Rudy Winklhofer",
                to: "/clients/testimonials/rudywinklhofer",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/rudy.jpg"),
                imageNavigationImageAlt: "Rudy Winklhofer"
              },
              {
                subMenuTitle: "Julia Pültz",
                to: "/clients/testimonials/juliapueltz",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/julia.jpg"),
                imageNavigationImageAlt: "Julia Pültz"
              },
              {
                subMenuTitle: "Martin Dess",
                to: "/clients/testimonials/martindess",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/martin.jpg"),
                imageNavigationImageAlt: "Martin Dess"
              },
              {
                subMenuTitle: "Michael Habla",
                to: "/clients/testimonials/michaelhabla",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/michael.jpg"),
                imageNavigationImageAlt: "Michael Habla"
              },
              {
                subMenuTitle: "Roman Bachler",
                to: "/clients/testimonials/romanbachler",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/roman.jpg"),
                imageNavigationImageAlt: "Roman Bachler"
              },
              {
                subMenuTitle: "Stefan Jäger",
                to: "/clients/testimonials/stefanjaeger",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/stefan.jpg"),
                imageNavigationImageAlt: "Stefan Jäger"
              },
              {
                subMenuTitle: "Joscha Sielemann",
                to: "/clients/testimonials/joschasielemann",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/joscha.jpg"),
                imageNavigationImageAlt: "Joscha Sielemann"
              },
              {
                subMenuTitle: "Christoph Wöhrl",
                to: "/clients/testimonials/christophwoehrl",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/christoph.jpg"),
                imageNavigationImageAlt: "Christoph Wöhrl"
              },
              {
                subMenuTitle: "Sabine Beitel",
                to: "/clients/testimonials/sabinebeitel",
                skipSubSubMenu: true,
                imageNavigationImageUrl: require("@/assets/images/testimonials/sabine.jpg"),
                imageNavigationImageAlt: "Sabine Beitel"
              }
            ]
          },
          {
            subMenuTitle: "Unternehmen",
            subMenuSubtitle: "Für diese Kunden arbeiten wir",
            to: "/clients/customers",
            subSubMenu: [
              {
                subMenuTitle: "Marpinion GmbH",
                to: "/clients/customers/marpinion",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "SellVision UG",
                to: "/clients/customers/sellvision",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "DETAIL Business Information GmbH",
                to: "/clients/customers/detail",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "Cosmo Consult SI GmbH",
                to: "/clients/customers/cosmo",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "ANTES Solutions GmbH",
                to: "/clients/customers/antes",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "Cosmema GmbH",
                to: "/clients/customers/cosmema",
                skipSubSubMenu: true
              }
            ]
          },
          {
            subMenuTitle: "Kooperationen",
            subMenuSubtitle: "Mit diesen Partnern arbeiten wir zusammen",
            to: "/clients/partners",
            subSubMenu: [
              {
                subMenuTitle: "Digitales Gründerzentrum Parsberg",
                to: "/clients/partners/techcampus",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "Cosmo Consult SI GmbH",
                to: "/clients/partners/cosmo",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "easyconsult GmbH",
                to: "/clients/partners/easyconsult",
                skipSubSubMenu: true
              },
              {
                subMenuTitle: "SellVision UG",
                to: "/clients/partners/sellvision",
                skipSubSubMenu: true
              }
            ]
          }
        ]
      },
      {
        title: "Say hi",
        subTitle: "Du findest uns in<br> Ober­haching und Parsberg",
        backgroundImage: require("@/assets/images/bg/contact_us.jpeg"),
        to: "/contact",
        skipSubMenu: true,
        visible: false
      }
    ] as MainMenuItemModel[]
  },
  mutations: {
    setActiveItemCenter: (state, payload: { x: number; y: number }) => {
      state.activeItem.center = payload;
    },
    setActiveItemBounds: (state, payload: any) => {
      state.activeItem.bounds = payload;
    },
    setActiveItemMenuItem: (state, payload: MainMenuItemModel) => {
      (state.activeItem.menuItem as any) = payload;

      if (payload !== null) {
        (state.activeItem.lastVisibleMenuItem as any) = payload;
      }
    },
    setActiveSubMenuItem: (state, payload: any) => {
      state.activeSubMenuItem = payload; // type: SubMenuItem
    },
    setIsInMainMenu: (state, payload: boolean) => {
      state.isInMainMenu = payload;
    },
    setIsInSubMenu: (state, payload: boolean) => {
      state.isInSubMenu = payload;
    },
    setIsInMainMenuHover: (state, payload: boolean) => {
      state.isInMainMenuHover = payload;
    },
    setCurrentActiveRoute: (state, payload: Route) => {
      state.currentActiveRoute = payload as any;
    },
    setIsAnimating: (state, payload: boolean) => {
      state.isAnimating = payload;
    },
    increaseIsAnimating: state => {
      state.isAnimatingCount += state.isAnimatingCount;
    },
    decreaseIsAnimating: state => {
      state.isAnimatingCount -= state.isAnimatingCount;
    },
    setWindowInnerHeight: (state, payload: number) => {
      state.windowInnerHeight = payload;
    },
    setWindowInnerWidth: (state, payload: number) => {
      state.windowInnerWidth = payload;
    },
    setBreakpoints: (state, payload: number) => {
      state.breakpoint.smAndDown = payload <= 960;
      state.breakpoint.mdAndUp = payload > 960;
    },
    setIsTouchDevice: (state, payload: boolean) => {
      state.isTouchDevice = payload;
    },
    setLastVisitedPageWithHome: (state, payload: string) => {
      state.lastVisitedPageWithHome = payload;
    },
    setAnalyticToolsAccepted: (state, payload: boolean) => {
      state.analyticToolsAccepted = payload;
    },
    setWindowDimensionChanged: (state, payload: boolean) => {
      state.windowDimensionChanged = payload;
    }
  },
  actions: {
    setIsAnimating: (context, payload: boolean) => {
      if (payload) {
        context.commit("increaseIsAnimating");
      } else {
        context.commit("decreaseIsAnimating");
      }

      context.commit("setIsAnimating", context.state.isAnimatingCount <= 0);
    },
    updateRoute: (context, payload: Route) => {
      context.commit("setCurrentActiveRoute", payload);
      context.commit("setIsInMainMenu", payload.name === "home");

      let foundActiveItem = null;

      for (let item of context.state.mainMenuItems) {
        if (payload.fullPath.includes(item.to)) {
          foundActiveItem = item;
        }
      }

      context.commit("setActiveItemMenuItem", foundActiveItem);
    },
    setActiveItemBounds: (context, el: HTMLDivElement) => {
      const bounds = el.getBoundingClientRect();
      const menuCenter: { x: number; y: number } = { y: 0, x: 0 };
      menuCenter.x = bounds.width / 2 + bounds.left;
      menuCenter.y = bounds.height / 2 + bounds.top;

      context.commit("setActiveItemCenter", menuCenter);
      context.commit("setActiveItemBounds", bounds);
    }
  },
  modules: {}
});
