

































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeProjectsSellvision extends Vue {
  headerText: string = "SellVision - Web Video Chat & Salestool";
  subHeaderText: string = "(SellVision UG)";
}
