




























import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomePortfolioTraining extends Vue {
  headerText: string = "Schulungen & Coachings";
}
