import axios from "axios";
import { ContactRequest, ContactResponse } from "@/connectors";

export class ContactService {
  static async sendContactRequest(
    contactRequest: ContactRequest
  ): Promise<ContactResponse> {
    try {
      const result = await axios.post<string>(
        "SendMailFromContactFrom",
        contactRequest
      );
      const response = new ContactResponse();
      response.success = result.status === 200;
      response.message = result.data;

      return response;
    } catch (e) {
      const response = new ContactResponse();
      response.success = false;

      return response;
    }
  }
}
