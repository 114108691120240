
















import { Component, Vue, Watch } from "vue-property-decorator";
import OsCookieBanner from "@/components/OsCookieBanner.vue";
import * as cookies from "js-cookie";

@Component({
  components: { OsCookieBanner }
})
export default class App extends Vue {
  loading: boolean = true;

  get height(): string {
    if (this.$store.state.isInMainMenu) {
      return this.$store.state.windowInnerHeight + "px";
    } else if (this.$store.state.mdAndUp) {
      return this.$store.state.windowInnerHeight + "px";
    }
    return "unset";
  }

  mounted() {
    this.onRouteChanged();
    this.loading = false;

    window.onresize = () => {
      this.setPageHeightAndWidthAndBreakpoints();
      this.$store.commit("setWindowDimensionChanged", true);
    };
    window.addEventListener("orientationchange", () => {
      this.setPageHeightAndWidthAndBreakpoints();
      this.$store.commit("setWindowDimensionChanged", true);
    });
    this.setPageHeightAndWidthAndBreakpoints();
    this.setIsTouchDevice();

    // this will disable zoom for iOS >= 10
    this.disableGestures();

    if (cookies.get("GA_accepted") === "1") {
      this.$store.commit("setAnalyticToolsAccepted", true);
    }
  }

  disableGestures() {
    document.addEventListener("gesturestart", e => {
      e.preventDefault();
    });
  }

  setPageHeightAndWidthAndBreakpoints() {
    this.$store.commit("setWindowInnerHeight", window.innerHeight);
    this.$store.commit("setWindowInnerWidth", window.innerWidth);
    this.$store.commit("setBreakpoints", window.innerWidth);
    document.body.style.height = window.innerHeight + "px";
  }

  setIsTouchDevice() {
    const isTouchDevice = "ontouchstart" in document.documentElement;
    this.$store.commit("setIsTouchDevice", isTouchDevice);
  }

  @Watch("$route")
  async onRouteChanged() {
    await this.$store.dispatch("updateRoute", this.$router.currentRoute);

    switch (this.$route.fullPath) {
      case "/legal-notice":
      case "/privacy-policy":
        document.body.style.background = "#ffffff";
        break;
      default:
        document.body.style.background = "#1a1a1a";
        break;
    }
  }

  @Watch("$store.state.isAnimating")
  async onIsAnimatingChanged(v: boolean) {
    document.body.style.overflow = v ? "hidden" : "auto";
  }
}
