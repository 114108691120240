


































import { Component, Vue, Watch } from "vue-property-decorator";
import MainMenu from "@/components/MainMenu/MainMenu.vue";
import animejs, { AnimeInstance } from "animejs";
import config from "./../../utils/global-config";

@Component({
  components: { MainMenu }
})
export default class HomeIndex extends Vue {
  contentBeforeEnter(el: HTMLElement) {
    if (this.$store.state.breakpoint.smAndDown) {
      animejs({
        targets: el,
        duration: 0,
        translateY: this.$store.state.windowInnerHeight + 80,
        easing: "linear"
      });
      return;
    }

    const clickedItemCenter = this.$store.state.activeItem.center;

    const originX = (window.innerWidth / 4) * 3;

    const deltaLeft = clickedItemCenter.x - originX;

    animejs({
      targets: el,
      translateX: deltaLeft,
      scale: 0,
      opacity: 0,
      duration: 0,
      easing: "linear"
    });
  }

  async contentEnter(el: HTMLElement, done: any) {
    this.$store.commit("setWindowDimensionChanged", false);

    if (this.$store.state.breakpoint.smAndDown) {
      animejs({
        targets: el,
        translateY: 0,
        duration: config.animationDuration,
        easing: config.easing,
        complete: () => {
          done();
          el.style.transform = "";
        }
      });
      return;
    }
    await this.$store.dispatch("setIsAnimating", true);

    let animationDuration = config.animationDuration;

    animejs({
      targets: el,
      translateX: 0,
      scale: 1,
      opacity: {
        value: 1,
        duration: animationDuration / 2,
        easing: "linear"
      },
      duration: animationDuration,
      easing: config.easing,
      complete: async () => {
        done();
        await this.$store.commit("setIsAnimating", false);
        el.style.transform = "";
      }
    });
  }

  async contentLeave(el: HTMLElement, done: any) {
    if (this.$store.state.breakpoint.smAndDown) {
      // I DID NOT FOUND THE CLASS WHO MESSES ME UP HERE....
      // should not be there
      el.style.left = "0";
      el.style.width = "100%";
      animejs({
        targets: el,
        duration: config.animationDuration,
        translateY: this.$store.state.windowInnerHeight + 80,
        easing: config.easing,
        complete: () => {
          done();
          el.style.transform = "";
        }
      });
      return;
    }

    let animationDuration = config.animationDuration;
    this.$store.commit("setIsAnimating", true);

    const clickedItemCenter = this.$store.state.activeItem.center;
    const originX = (window.innerWidth / 4) * 3;

    const deltaLeft = clickedItemCenter.x - originX;

    const bounds = this.$store.state.activeItem.bounds;

    if (!bounds || this.$store.state.windowDimensionChanged) {
      animationDuration = 0;
    }

    animejs({
      targets: el,
      translateX: deltaLeft,
      scale: 0,
      opacity: {
        value: 0,
        duration: animationDuration / 2,
        easing: "linear"
      },
      duration: animationDuration,
      easing: config.easing,
      complete: async () => {
        done();
        await this.$store.commit("setIsAnimating", false);
        this.$store.commit("setWindowDimensionChanged", false);
        el.style.transform = "";
      }
    });
  }
}
