






























import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeClientsPartnersPartnerEasyConsult extends Vue {
  headerText: string = "easyconsult GmbH";
  subHeaderText: string = "CRM erfolgreich einfach";
}
