export class ContactRequest {
  name: string = "";
  email: string = "";
  phone: string = "";
  message: string = "";
  captchaToken: string | null = null;

  constructor(init?: Partial<ContactRequest>) {
    Object.assign(this, init);
  }
}
