























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as cookies from "js-cookie";
import { bootstrap } from "vue-gtag";

@Component
export default class extends Vue {
  cookiesAccepted: boolean = false;
  cookieBannerInitialLoadDelayDone: boolean = false;

  created() {
    if (cookies.get("internal_cookies_accepted") === "1") {
      this.cookiesAccepted = true;
    }
  }

  mounted() {
    setTimeout(() => {
      this.cookieBannerInitialLoadDelayDone = true;
    }, 600);
  }

  openUrl(url: string) {
    window.open(url, "_self");
  }

  async acceptCookies(enableAnalyticTools: boolean) {
    const now = new Date();

    cookies.set("internal_cookies_accepted", "1", {
      expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
    });
    this.cookiesAccepted = true;

    if (enableAnalyticTools) {
      this.$store.commit("setAnalyticToolsAccepted", true);
    }
  }

  @Watch("$store.state.analyticToolsAccepted")
  async onAnalyticToolsAcceptedChange(v: boolean) {
    if (v) {
      this.enableGoogleAnalytics();
    } else {
      this.disableGoogleAnalytics();
    }
  }

  enableGoogleAnalytics() {
    if (cookies.get("GA_accepted") !== "1") {
      const now = new Date();
      cookies.set("GA_accepted", "1", {
        expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
      });
    }
    bootstrap().then(gtag => {});
  }

  disableGoogleAnalytics() {
    if (cookies.get("GA_accepted") === "1") {
      const now = new Date();
      cookies.set("GA_accepted", "0", {
        expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
      });
      this.$gtag.optOut();
    }
  }
}
