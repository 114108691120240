var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:[
    !_vm.$store.state.isInMainMenu && _vm.$store.state.breakpoint.smAndDown
      ? 'mobile'
      : ''
  ],style:({
    'min-height':
      _vm.$store.state.isInMainMenu || _vm.$store.state.isInSubMenu
        ? 'unset'
        : _vm.$store.state.ContentSiteMinHeight + 'px'
  })},[_c('div',{staticClass:"section--menu fill-height"},[_c('main-menu',{attrs:{"items":_vm.$store.state.mainMenuItems}})],1),_c('transition',{on:{"before-enter":_vm.contentBeforeEnter,"enter":_vm.contentEnter,"leave":_vm.contentLeave}},[(!_vm.$store.state.isInMainMenu)?_c('div',{staticClass:"section--content",class:[_vm.$store.state.breakpoint.smAndDown ? 'mobile' : '']},[_c('router-view')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }