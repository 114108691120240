













































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ArrowButton from "@/components/ArrowButton.vue";
import { SubMenuItem } from "@/components/SubMenu/sub-menu-item.model";
import OsCloseButton from "@/components/OsCloseButton.vue";
import OsLegalInformation from "@/components/OsLegalInformation.vue";
import OsImageNavigation from "@/components/OsImageNavigation.vue";
import OsNavigationIndicator from "@/components/OsNavigationIndicator.vue";
@Component({
  components: {
    OsNavigationIndicator,
    OsImageNavigation,
    OsLegalInformation,
    OsCloseButton,
    ArrowButton
  }
})
export default class ContentViewLayout extends Vue {
  @Prop({
    required: false,
    default: () => {
      return [];
    }
  })
  items!: SubMenuItem[];

  @Prop()
  activeItem!: number;

  @Prop({ default: "slide-fade" })
  slotTransition!: string;

  @Prop({ default: null })
  backTo!: string;

  @Prop({ default: true })
  absoluteBottom!: boolean;

  @Prop({ default: true })
  displayBottom!: boolean;

  internalItems!: SubMenuItem[];
  activeItemInternal: number = -1;

  navigateByInternalNavigation: boolean = false;

  created() {
    this.internalItems = this.items;
  }

  mounted() {
    this.setActiveItemNumberByIndex();
  }

  @Watch("activeItemInternal")
  commitActiveSubMenuItem() {
    this.$store.commit(
      "setActiveSubMenuItem",
      this.internalItems[this.activeItemInternal]
    );
  }

  @Watch("activeItem")
  onActiveItemChange() {
    this.activeItemInternal = this.activeItem;
  }

  @Watch("$route")
  onRouteChanged() {
    // for standard browser back-button
    this.setItemsByIndex();
    this.setActiveItemNumberByIndex();
  }

  setItemsByIndex() {
    if (!this.navigateByInternalNavigation) {
      let fullPath = this.$route.fullPath;
      if (fullPath.endsWith("/")) {
        fullPath = fullPath.substring(0, fullPath.length - 1);
      }
      const mainMenuItems = this.$store.state.mainMenuItems;

      for (let i = 0; i < mainMenuItems.length; i++) {
        let mainMenuItem = mainMenuItems[i];
        if (mainMenuItem.to === fullPath) {
          this.internalItems = [];
          return;
        }
        if (mainMenuItem.subMenu) {
          for (let j = 0; j < mainMenuItem.subMenu.length; j++) {
            let subMenuItem = mainMenuItem.subMenu[j];
            if (subMenuItem.to === fullPath) {
              this.internalItems = mainMenuItem.subMenu;
              return;
            }
            if (subMenuItem.subSubMenu) {
              for (let k = 0; k < subMenuItem.subSubMenu.length; k++) {
                let subSubMenuItem = subMenuItem.subSubMenu[k];
                if (subSubMenuItem.to === fullPath) {
                  this.internalItems = subMenuItem.subSubMenu;
                  return;
                }
              }
            }
          }
        }
      }
    } else {
      this.navigateByInternalNavigation = false;
    }
  }

  setActiveItemNumberByIndex() {
    if (!this.navigateByInternalNavigation && !this.$store.state.isInSubMenu) {
      this.activeItemInternal = this.internalItems.findIndex(
        item => item.to === this.$route.path.replace(/\/$/, "")
      );
      this.commitActiveSubMenuItem();
    } else {
      this.navigateByInternalNavigation = false;
    }
  }

  previousItem() {
    if (this.isFirstItem) {
      return;
    }
    this.navigateByInternalNavigation = true;
    this.activeItemInternal--;
    this.$router.push(this.internalItems[this.activeItemInternal].to);
  }

  nextItem() {
    if (this.isLastItem) {
      return;
    }
    this.navigateByInternalNavigation = true;
    this.activeItemInternal++;
    if (this.internalItems[this.activeItemInternal].to.length > 0) {
      this.$router.push(this.internalItems[this.activeItemInternal].to);
    }
  }

  get isFirstItem(): boolean {
    return this.activeItemInternal === 0;
  }

  get isLastItem(): boolean {
    return (
      this.activeItemInternal ===
      this.internalItems.filter(item => !!item.to && item.to.length > 0)
        .length -
        1
    );
  }
}
