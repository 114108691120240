













































import { Component, Vue } from "vue-property-decorator";
import DefaultContentLayout from "@/layouts/DefaultContentLayout.vue";
@Component({
  components: { DefaultContentLayout: DefaultContentLayout }
})
export default class HomeClientsCustomersMarpinion extends Vue {
  headerText: string = "SellVision UG";
  subHeaderText: string = "Das mobile Videoverkaufstool für Automobilverkäufer";
}
