import { render, staticRenderFns } from "./OsButton.vue?vue&type=template&id=5054a902&scoped=true&"
import script from "./OsButton.vue?vue&type=script&lang=ts&"
export * from "./OsButton.vue?vue&type=script&lang=ts&"
import style0 from "./OsButton.vue?vue&type=style&index=0&id=5054a902&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5054a902",
  null
  
)

export default component.exports