




























































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import OsInfoHeader from "@/components/OsInfoHeader.vue";
import OsInfoFooter from "@/components/OsInfoFooter.vue";
@Component({
  components: { OsInfoFooter, OsInfoHeader }
})
export default class PrivacyPolicy extends Vue {
  gaOptOut() {
    this.$store.commit("setAnalyticToolsAccepted", false);
    alert("Analyse Tools deaktiviert.");
  }
}
